import Api from "../services";
import { store } from "../store";

export const createOrder = async (payload) => {
  try {
    const response = await Api.post("/order", payload);
    return response.result ? response.result : response.message;
  } catch (error) {
    return error.response.data.message;
  }
};

export const createAgricOrder = async (payload) => {
  try {
    const response = await Api.post(
      "/order/create-parent-agric-order",
      payload
    );
    return response.result ? response.result : response.message;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getAllOrders = async (params) => {
  try {
    const { userId } = store.getState().auth.user;
    const query = new URLSearchParams({
      ...params,
      createdBy: userId,
    }).toString();
    const response = await Api.get(`/order?${query}`);
    return response.result ? response.result : response.message;
  } catch (error) {
    return error.response?.data?.message || "";
  }
};

export const cancelOrder = async (payload) => {
  try {
    const response = await Api.post(`/order/cancel-order`, payload);
    return response.result ? response.result : response.message;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getOrderDetails = async (orderId) => {
  try {
    const response = await Api.get(`/order/${orderId}`);
    return response.result ? response.result : response.message;
  } catch (error) {
    return error.response.data.message;
  }
};
export const getBids = async (orderId) => {
  try {
    const response = await Api.get(`/bid?orderId=${orderId}`);
    return response.result ? response.result : response.message;
  } catch (error) {
    return error.response.data.message;
  }
};

export const updateOrderDetails = async (orderId, payload) => {
  try {
    const response = await Api.update(`/order/${orderId}`, payload);
    return response.result ? response.result : response.message;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getCustomerCreditStats = async (params = "") => {
  try {
    const query = new URLSearchParams({
      ...params,
    }).toString();
    const response = await Api.get(`/order/customer-credit-stats?${query}`);
    return response.result ? response.result : response.message;
  } catch (error) {
    // console.log(error);
    return error.response?.data?.message;
  }
};

export const getSettingsData = async () => {
  try {
    const response = await Api.get(`/settings`);
    return response.result ? response.result : response.message;
  } catch (error) {
    return error.response.data.message;
  }
};

export const changeLoadingDocStatus = async (payload) => {
  try {
    const response = await Api.post(
      `/order/change-loading-doc-status`,
      payload
    );
    return response.result ? response.result : response.message;
  } catch (error) {
    return error.response.data.message;
  }
};

export const verifyOrRejectAllLoadingDoc = async (payload) => {
  try {
    const response = await Api.post(
      `/order/verify-or-reject-all-loading-docs`,
      payload
    );
    return response.result ? response.result : response.message;
  } catch (error) {
    return error.response.data.message;
  }
};

export const changePodStatus = async (payload) => {
  try {
    const response = await Api.post(`/order/change-pod-status`, payload);
    return response.result ? response.result : response.message;
  } catch (error) {
    return error.response.data.message;
  }
};

export const verifyOrRejectAllPods = async (payload) => {
  try {
    const response = await Api.post(
      `/order/verify-or-reject-all-pods`,
      payload
    );
    return response.result ? response.result : response.message;
  } catch (error) {
    return error.response.data.message;
  }
};
