import React, { lazy, useEffect } from "react";
import { HashRouter, Switch } from "react-router-dom";

import PublicRoute from "../router/publicRoute";
// import PrivateRoute from "../Routes/PrivateRoute";
import LazyLoader from "../LazyWrapper/";
import { routes } from "../utils/routesConfig";
import PrivateRoute from "./privateRoute";
import { useDispatch, useSelector } from "react-redux";
import ScrollToSection from "../components/ScrollToSection";
import Home from "../pages/Home/";
import {
  getNotificationPermission,
  getNotificationToken,
} from "../utils/config";
import { updateCustomer } from "../requests/auth.requests";
import { updateUser } from "../slice/auth.slice";
import ChildAgricOrderDetails from "../pages/ChildAgriOrderDetails";
// const Home = lazy(() => import("../pages/Home/"));
const Career = lazy(() => import("../pages/Career/"));
const Login = lazy(() => import("../pages/Login/"));
const Onboarding = lazy(() => import("../pages/Onboarding/"));
const Orders = lazy(() => import("../pages/Orders/"));
const AllFavoriteOrders = lazy(() => import("../pages/AllFavoriteOrders/"));
const OrderDetails = lazy(() => import("../pages/OrderDetails/"));
const CompletedOrders = lazy(() => import("../pages/OrdersCompleted/"));
const LiveOrders = lazy(() => import("../pages/LiveOrders/"));
const AgriOrders = lazy(() => import("../pages/AgriOrders"));
const Profile = lazy(() => import("../pages/Profile/"));
const CreateOrder = lazy(() => import("../pages/CreateOrder/"));
const Billings = lazy(() => import("../pages/Billings/"));
const PageNotFound = lazy(() => import("../pages/PageNotFound/"));

export default function AppRoutes() {
  const { clientToken } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleNotification = async (clientToken) => {
    try {
      const havePermission = await getNotificationPermission();
      const deviceToken = await getNotificationToken();

      if (havePermission) {
        const response = await updateCustomer({
          deviceToken,
        });
        if (typeof response !== "string") {
          dispatch(updateUser({ user: response }));
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // to re-render the routes on auth change
  useEffect(() => {
    if (clientToken) {
      handleNotification();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientToken]);

  return (
    <HashRouter>
      <ScrollToSection />
      <Switch>
        <PublicRoute restricted={true} component={Home} path="/" exact />
        <PublicRoute
          restricted={true}
          component={LazyLoader(Career)}
          path="/career"
          exact
        />
        <PublicRoute
          restricted={true}
          component={LazyLoader(Login)}
          path={routes.LOGIN}
          exact
        />
        <PublicRoute
          restricted={true}
          component={LazyLoader(Onboarding)}
          path={routes.ONBOARDING}
          exact
        />
        <PrivateRoute
          component={LazyLoader(Orders)}
          path={routes.ORDERS}
          exact
        />
        <PrivateRoute
          component={LazyLoader(OrderDetails)}
          path={routes.ORDER_DETAILS}
          exact
        />
        <PrivateRoute
          component={LazyLoader(ChildAgricOrderDetails)}
          path={routes.AGRIC_ORDER_DETAILS}
          exact
        />
        <PrivateRoute
          component={LazyLoader(CompletedOrders)}
          path={routes.ORDER_COMPLETED}
          exact
        />
        <PrivateRoute
          component={LazyLoader(LiveOrders)}
          path={routes.ORDER_LIVE}
          exact
        />
        <PrivateRoute
          component={LazyLoader(AgriOrders)}
          path={routes.AGRI_ORDERS}
          exact
        />
        <PrivateRoute
          component={LazyLoader(AllFavoriteOrders)}
          path={routes.ALL_FAVORITE_ORDERS}
          exact
        />
        <PrivateRoute
          component={LazyLoader(Profile)}
          path={routes.PROFILE}
          exact
        />
        <PrivateRoute
          component={LazyLoader(CreateOrder)}
          path={routes.CREATE_ORDER}
          exact
        />
        <PrivateRoute
          component={LazyLoader(Billings)}
          path={routes.BILLINGS}
          exact
        />
        <PublicRoute
          restricted={false}
          component={LazyLoader(PageNotFound)}
          path="*"
        />
      </Switch>
    </HashRouter>
  );
}
